










import { Vue, Component, Prop } from "vue-property-decorator";
import { DatePicker } from "element-ui";
@Component({
    components: {
        "el-date-picker": DatePicker
    }
})
export default class FormItemDatePicker extends Vue {
    @Prop({
        type: [String, Date, Object],
        required: false,
        default: () => ({})
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    val: any = ""; //RadioGroup 绑定值

    // 钩子，选中值发生变化时触发
    change(data: any) {
        // console.log(data);
        this.$emit("onchange", data);
    }

    mounted() {
        if (this.value instanceof Date) {
            this.val = this.value;
        } else {
            this.value == "" || this.value == null
                ? true
                : (this.val = new Date(this.value));
        }
    }
}
